import React from 'react'
import { addWebPToImageURL } from 'Helpers/utilities'

const HeroBannerSlideWithBackgroundImageBlock = ({ data, inEditMode }) => {
  const { BackgroundImage, TextColor, HeroHeading, HeroSubheading, HeroCta, ButtonColor, HeroCtaAlignment, ContentTextAlignment } = data;
  const Link = HeroCta.Value[0];
  const heroCtaAlignment = HeroCtaAlignment === 'left' ? 'text-left' : HeroCtaAlignment === 'center' ? 'text-center' : 'text-right';
  const textAlignment = ContentTextAlignment === 'left' ? 'text-left' : ContentTextAlignment === 'center' ? 'text-center' : 'text-right';

  const buttonColorClasses = ButtonColor === 'light' ? 'bg-[#d3d9ff] hover:bg-[#b6b8ff] text-black border-[#d3d9ff] hover:border-[#b6b8ff]' : 'bg-violet hover:bg-violet-dark text-white border-violet hover:border-violet-dark';
  return (
    <div className='relative bg-no-repeat bg-cover bg-center h-full' style={{ backgroundImage: `url(${addWebPToImageURL(BackgroundImage?.Url)})` }}>
      <div className='container mx-auto max-w-screen-xl h-full'>
        <div className={`flex flex-col justify-center h-full mx-6 sm:mx-8 md:mx-12 lg:mx-12 xl:mx-24 ${TextColor}`} >
          <div className={`text-xl md:text-3xl lg:text-6xl font-medium font-display py-2 lg:py-6 ${textAlignment}`}>
            <h1 dangerouslySetInnerHTML={{ __html: HeroHeading }}></h1>
          </div>
          <div className={`lg:text-lg md:text-base text-sm md:font-medium md:font-display font-body mb-4 ${textAlignment}`}>
            <h4 dangerouslySetInnerHTML={{ __html: HeroSubheading }}></h4>
          </div>
          {Link && (
            <div className={`mt-2 ${heroCtaAlignment}`}>
              <a data-epi-edit="HeroCta" href={`${Link?.Href}`} target={`${Link?.Target}`} title={`${Link?.Title}`}
                className={`focus:outline-none rounded-full font-medium font-body md:text-base 
                sm:text-sm text-xs border-2 lg:py-2.5 py-2 md:px-8 sm:px-6 px-4 whitespace-nowrap ${buttonColorClasses}`}>
                {Link?.Text}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(HeroBannerSlideWithBackgroundImageBlock)
